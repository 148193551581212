import React from "react"
import ThemeSwitch from "./theme-switch"

const MenuBar = ({ size, title, children }) => {
    const menu = children ? (
        <>
            <input className="expand-switch left size-h5" aria-label="Menu Toggle" type="checkbox" />
            <nav className="menu">{ children }</nav>
        </>) : (<></>);
    return (
        <header className="bar sticky">
            <div className={`bar-body size-h5 ${size ? 'size-'+size : ''}`}>
                <div className="title size-h2">{ title }</div>
                <div className="right"><ThemeSwitch /></div>
                { menu }
            </div>
        </header>
    );
}

export default MenuBar