import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby";

export default ({children}) => {
    const {site: {siteMetadata: { title }}} = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);
    const titleParts=[]
    if (title)
        titleParts.push(title)
    if (children)
        titleParts.push(children)
    const displayTitle = titleParts.join(' | ')
    return <Helmet><title>{displayTitle}</title></Helmet>
}
