import React, { useState } from "react"

const ThemeSwitch = () => {
    const toggleTheme = () => {
        const root = window.document.body;
        const newTheme = (root.classList.contains('theme-dark') ? 'theme-light' : 'theme-dark');
        root.classList.remove('theme-light');
        root.classList.remove('theme-dark');
        root.classList.add(newTheme);
        localStorage.setItem('theme', newTheme);
    }
    return <span className="theme-switch" onClick={toggleTheme} />;
};

export default ThemeSwitch