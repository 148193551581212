import React from "react"
import { Link } from "gatsby"
import usePeachOptions from "../hooks/usePeachOptions"

export default () => {
    const { menu } = usePeachOptions();
    return (<>
        {menu.map( ({ icon, name, path }) => (
            <Link key={name} to={path} activeClassName="active">{icon ? <span className={icon} />: null} {name}</Link>
        ))}
    </>);
};

