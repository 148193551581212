import { useStaticQuery, graphql } from "gatsby"

const usePeachOptions = () => {
    const data = useStaticQuery(graphql`
        query {
            sitePlugin(name: {eq: "gatsby-theme-peach"}) {
                pluginOptions
            }
        }
    `);
    return data.sitePlugin.pluginOptions;
}

export default usePeachOptions