import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export default () => {
    const {
        siteBuildMetadata: { buildTime },
        site: { siteMetadata: { author }}
    } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    author
                }
            }
            siteBuildMetadata {
                buildTime(formatString: "YYYY")
            }
        }      
    `);
    return (<>{ author ? `© ${buildTime} ${author} · ` : '' }Built with <a href="https://www.gatsbyjs.com/">Gatsby</a></>);
};

