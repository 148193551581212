import React from "react"
import TeX from "@matejmazur/react-katex"
import { Helmet } from "react-helmet"
import { MDXProvider } from "@mdx-js/react"
import { graphql, useStaticQuery, Link } from "gatsby"

import '../styles/peach.scss'
import usePeachOptions from "../hooks/usePeachOptions"
import Title from "./title"
import Footer from "./footer"
import Menu from "./menu"
import MenuBar from "./menu-bar"
import PageTitle from "./page-title"

const components = {
    Link,
    Helmet,
    PageTitle,
    TeX
}

const Layout = ({ children }) => {
    const { screenSize, menuSize } = usePeachOptions();
    const {site: {siteMetadata: {
        author,
        description
    }}} = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    author
                    description
                }
            }
        }
    `);
    return (
        <div className={`document ${screenSize ? 'size-'+screenSize : ''}`}>
            <PageTitle />
            <Helmet htmlAttributes={{ lang: 'en' }}>
                { description ? <meta name="description" content={description} /> : null }
                { author ? <meta name="author" content={author} /> : null }
            </Helmet>
            <MenuBar title={<Title />} size={menuSize}>{<Menu />}</MenuBar>
            <main>
                <MDXProvider components={components}>{ children }</MDXProvider>
            </main>
            <footer className="footer-text"><Footer /></footer>
        </div>
    );
}

export default Layout