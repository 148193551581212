import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

export default () => {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);
    return (<Link to="/">{ site.siteMetadata.title }</Link>);
};

